import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useEffect } from 'react'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  // Add hover effect
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
    cursor: 'pointer',
  },
}))

const Resumo = ({ tabData, form, handleChangeFilter, data }) => {
  const groupedData = tabData.reduce((acc, item) => {
    const { calibre, tipo, calibreID } = item
    if (!acc[calibre]) {
      acc[calibre] = { Treinamento: 0, Competição: 0, total: 0, calibreID }
    }
    acc[calibre][tipo] += 1
    acc[calibre].total += 1
    return acc
  }, {})

  const formattedData = Object.entries(groupedData).map(
    ([calibre, counts]) => ({
      calibre,
      calibreID: counts.calibreID,
      treinamento: counts.Treinamento,
      competicao: counts.Competição,
      total: counts.total,
    })
  )

  const columns = [
    { id: 'calibre', label: 'Calibre', width: '34%' },
    { id: 'treinamento', label: 'Treinamento', width: '23%' },
    { id: 'competicao', label: 'Competição', width: '23%' },
    { id: 'total', label: 'Total', width: '20%' },
  ]

  const onRowClick = (row) => {
    const calibre = {
      nome: row.calibre,
      id: row.calibreID,
    }
    form.setValue('calibre', calibre)
    form.setValue('tab', 'All')
    handleChangeFilter(data)
  }

  const rows = formattedData

  useEffect(() => {
    form.setValue('calibre', null)
    handleChangeFilter(data)
  }, [form])

  return (
    <TableContainer component={Paper}>
      <Table aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <StyledTableCell
                key={column.id}
                align="center"
                style={{ minWidth: column.minWidth }}
              >
                {column.label}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.calibre} onClick={() => onRowClick(row)}>
              <StyledTableCell component="th" scope="row" align="center">
                {row.calibre}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.treinamento}
              </StyledTableCell>
              <StyledTableCell align="center">{row.competicao}</StyledTableCell>
              <StyledTableCell align="center" style={{ fontWeight: 'bold' }}>
                {row.total}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Resumo
